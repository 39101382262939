/*@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(./fonts/Gilroy-SemiBold.ttf) format('truetype');
}*/



* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    
}

.App {
    justify-content: center;
    align-content: center;
}
body {
    /*background: var(--color-bg)*/
    /*background:-moz-radial-gradient(circle at 0% 8%, #002c96 0%, #00bcd4 100%);*/
    background: white;
    /* safari 5.1+,chrome 10+ */
    /*background:-webkit-radial-gradient(circle at 0% 8%, rgba(0, 44, 150, 1) 0%, rgba(0, 188, 212, 1) 100%);*/

    /* opera 11.10+ */
    /*background:-o-radial-gradient(circle at 0% 8%, rgba(0, 44, 150, 1) 0%, rgba(0, 188, 212, 1) 100%);*/

    /* ie 10+ */
    /*background:-ms-radial-gradient(circle at 0% 8%, rgba(0, 44, 150, 1) 0%, rgba(0, 188, 212, 1) 100%);*/

    /* global 92%+ browsers support */
    /*background:radial-gradient(circle at 0% 8%, rgba(0, 44, 150, 1) 0%, rgba(0, 188, 212, 1) 100%);*/
}

a {
    color: unset;
    text-decoration: none;
}

div {
  white-space: pre-wrap;
}


.gradient__card {
    background: var(--gradient-card);
}

.gradient__bg {

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 0% 0%, #002c96 0%, #00f2ff 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 0% 0%, rgba(0, 44, 150, 1) 0%, rgba(0, 242, 255, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 0% 0%, rgba(0, 44, 150, 1) 0%, rgba(0, 242, 255, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 0% 0%, rgba(0, 44, 150, 1) 0%, rgba(0, 242, 255, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 0% 0%, rgba(0, 44, 150, 1) 0%, rgba(0, 242, 255, 1) 100%);

    /*background:-moz-radial-gradient(circle at 0% 0%, #002c96 0%, #00f2ff 100%);

    background:-webkit-radial-gradient(circle at 0% 0%, rgba(0, 44, 150, 1) 0%, rgba(0, 242, 255, 1) 100%);

    background:-o-radial-gradient(circle at 0% 0%, rgba(0, 44, 150, 1) 0%, rgba(0, 242, 255, 1) 100%);

    background:-ms-radial-gradient(circle at 0% 0%, rgba(0, 44, 150, 1) 0%, rgba(0, 242, 255, 1) 100%);

    background:radial-gradient(circle at 0% 0%, rgba(0, 44, 150, 1) 0%, rgba(0, 242, 255, 1) 100%);*/
    
}
            
.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.normal-text-color{
  color: var(--color-text);
}

.section__padding {
    padding: 4rem 9.5rem;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.section__margin {
    margin: 4rem 9.5rem;
    max-width: 1340px;
    margin-left: auto;
    margin-right: auto;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 1300px) {
    .section__padding {
      padding: 10%;
  }
  .section__margin {
      margin: 10%;
  }
  }

  @media screen and (max-width: 1080px) {
    .section__padding {
      padding: 5%;
  }
  .section__margin {
      margin: 5%;
  }
  }

  @media screen and (max-width: 1050px) {

  }

  @media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }
    .section__margin {
        margin: 4rem;
    }
  }

  @media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }
    .section__margin {
        margin: 4rem 2rem;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-7-26 21:8:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* ----------------------------------------------
 * Generated by Animista on 2022-7-28 12:11:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

.slide-in-fwd-center {
	-webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-out-fwd-center {
  100% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-out-fwd-center {
  100% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

.slide-out-fwd-center {
	-webkit-animation: slide-out-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-out-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}