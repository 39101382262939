/*@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url(https://db.onlinewebfonts.com/c/000833a3a58fb083c44b9de42c2ff0a2?family=Gilroy-SemiBold);


:root {
  --font-family: 'Inter';
  --title-font: 'Gilroy-SemiBold';
  --gradient-text: linear-gradient(89.97deg, #F49867 1.84%, #ff4820 102.67%);
  --gradient-text: linear-gradient(89.97deg, rgb(251, 119, 11) 1.84%, rgb(208, 74, 167) 40.67%,rgb(101, 87, 249) 100%);
  --gradient-bar: linear-gradient(103.22deg, #2956e9 -13.86%, #F49867 99.55%);
  --gradient-bar: linear-gradient(89.97deg, rgb(251, 119, 11) 1.84%, rgb(208, 74, 167) 40.67%,rgb(101, 87, 249) 100%);
  --gradient-card: rgb(41, 90, 249);/*linear-gradient(103.22deg, #0088c7 -13.86%, #002c96 99.55%);*/
  --solid-window:#002975;
  --solid-card: #0049A5;
  --light-blue: #0088c7;
  --dark-blue:#002c96;
  --mid-blue:#1a4bbd;
  --bright-blue:rgb(41, 90, 249);
  --color-bg: #4a95d3;
  /*--color-bg: linear-gradient(103.22deg, #002c96 -13.86%, #00f2ff 99.55%);*/
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #2f343a;
  --color-subtext: #FF8A71;
  --color-orange: #ff5833 ;

/*For FAQ Section */
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-purple: #4b145b;
  --clr-red-special: #b4345c;
  --clr-grey-special: #eae6eb;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.5s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 920px;

}
