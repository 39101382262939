.frenchie-community-container{
    margin: 4rem 1rem;
    background-image: url(../assets/frenchieClubBanner.png);
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 2rem 4rem;
    padding-bottom: 2rem;
    text-align: center;
}

.frenchie-community-title{
    color: white;
    font-size: 20px;
    font-weight: 800;
    font-family: var(--title-font);
    
}

.frenchie-community-text{
    color: white;
    font-size: 12px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 0rem;
    font-family: 'Inter';
}

.button-2{
    margin-top: 1.5rem;
    padding: 0.8rem 2.2rem;
    color:var(--bright-blue);
    font-family: 'Inter';
    font-weight: 500;
    background:  #ffff;
    border-radius: 2rem;
    border: 0;
    outline: none;
    cursor: pointer;
}

.button-2:hover{
    color: #ffff;
    background: var(--gradient-text);
}


@media screen and (max-width: 750px) {
    .frenchie-community-container{
        background-image: none;
        background: var(--bright-blue);
        border-radius: 20px;
        padding: 2rem 1.5rem;
    }
}