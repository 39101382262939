.gpt3__whatgpt3-card {
    /*display:flex;
    
    flex-direction: column;*/
    /*max-width: 1050px;*/
    
    padding: 3rem 0rem;
    border-radius: 30px;
    padding-bottom: 0;
    width: 100%;
    
}

.gpt3__whatgpt3 {
    /*max-width: 1050px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.what-title{
    text-align: center;
    font-size:34px;
    color: white;
    max-width: 765px;
    margin-left: auto;
    margin-right: auto;
    font-family: var(--title-font);
}

.what-text{
    text-align: center;
    margin-top: 1rem;
    color: rgb(220, 220, 220);
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    max-width: 765px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Inter';
}

.gpt3__whatgpt3-feature {
    display: flex;
}

.videoFrame {
    display: flex;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
    max-width: 700px;
}

.gpt3__whatgpt3-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 3rem 0 2rem;
}

.gpt3__whatgpt3-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--title-font);
    max-width: 510px;
}

.gpt3__whatgpt3-heading p {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.gpt3__whatgpt3-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

.gpt3__whatgpt3-container_video {
    
    margin: auto;
    margin-top: 3rem;
    /*padding-bottom: 33%;
    width: 60%;*/
}

/*.gpt3__whatgpt3-container_video iframe{
    
    
    
}*/

.iframe-container-limiter {
    /*max-width: 800px;
    max-height: 450px;*/
    margin: auto;
    max-width: 765px;
}

.iframe-container{
    position: relative;
    width: 90%;
    
   

    padding-bottom: 50.625%;
    
   
    
    height: 0;
    
    margin:auto;
    margin-top: 3rem;
  }
  .iframe-container iframe{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px 15px 0 0;
    
  }

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}